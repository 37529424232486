import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView, clickOutsideHandler, ContextualBalloon, View, Dialog } from '@ckeditor/ckeditor5-ui';
import FormView from './wirisview';
import '../styles.css';
import getRangeText from "../tooltip/utils";
import wirisIcon from './ckeditor5-formula.svg';
import DoubleClickObserver from "./doubleClickObserver";
import { b64_to_utf8, utf8_to_b64 } from './utils';

export default class Wirisui extends Plugin {
    static get requires() {
        return [ContextualBalloon, Dialog];
    }

    constructor(editor) {
        super(editor);
        this.isDialogVisible = false;
        this._sheetOnBlurErrorsCallBackFn = () => {
            console.log("isDialogVisible ==> ", this.isDialogVisible);
            if (!this.isDialogVisible) {
                return {
                    error: false,
                    messages: []
                };
            }

            const editor = this.editor;
            const angularInjector = angular.element('*[data-ng-app]').injector();
            const $translate = angularInjector.get('$translate');
            const uuid = editor.sourceElement.getAttribute('id').replace('_ck5', '');
            let question_part = null;
            let questionHeadline = false;
            
            if (angular.isFunction(this._fetchContext()?.getParentsEntryByUUID)) {
                const parents = this._fetchContext().getParentsEntryByUUID(uuid);
                question_part = _.find(parents, item => item.getType() === 'questionPart');
                const sheet = _.find(parents, item => item.getType() === 'sheet');
                
                if (question_part) {
                    if (question_part.getTitle()) {
                        questionHeadline = question_part.getTitle() + ' (' + $translate.instant("PREFIX.QUESTION") + ' ' + 
                            (sheet.getTitle() ? sheet.getTitle() : sheet.getShortTitle()) + ')';
                    } else {
                        const tmpSheetShortTitle = (sheet.getShortTitle() != "(Default Question)" ? " " + sheet.getShortTitle() : '');
                        const tmpQPShortTitle = (question_part.getShortTitle() != "(Default Question)" ? question_part.getShortTitle() : '');

                        questionHeadline = sheet.getTitle() ?
                            sheet.getTitle() + tmpSheetShortTitle :
                            sheet.getShortTitle() + tmpQPShortTitle;

                        questionHeadline = $translate.instant("PREFIX.QUESTION") + ' ' + questionHeadline;
                    }
                } else {
                    questionHeadline = sheet.getTitle() ? 
                        (sheet.getTitle() + '  ' + sheet.getShortTitle()) : 
                        ($translate.instant("PREFIX.QUESTION-PART") + ' ' + (sheet.getShortTitle()));
                }
            }

            return {
                error: true,
                messages: [{
                    'title': $translate.instant('MODULES.WIRIS-CKEDITOR-PLUGIN.SHEET-LEAVE-TITLE'),
                    'message': $translate.instant('MODULES.WIRIS-CKEDITOR-PLUGIN.SHEET-LEAVE-MSG', {s: questionHeadline}),
                    'status': 'WARNING',
                    'group': question_part ? question_part.getUUID() : false,
                    'message_key': "wiris_editor"
                }]
            };
        };

        this._fetchContext = () => {
            if (!this._fetchContextCache) {
                const angularInjector = angular.element('*[data-ng-app]').injector();
                this._fetchContextCache = angularInjector.get('TaskDataService').fetchContext();
            }
            return this._fetchContextCache;
        };
    }

    init() {
        const editor = this.editor;
        const angularInjector = angular.element('*[data-ng-app]').injector();
        const SheetLeaveService = angularInjector.get('SheetLeaveService');

        const originalOpenDialog = this._openDialog;
        this._openDialog = (...args) => {
            SheetLeaveService.bindOnLeaveCallBack(
                'wiriseditor_' + editor.sourceElement.getAttribute('id').replace('_ck5', ''),
                this._sheetOnBlurErrorsCallBackFn
            );
            
            originalOpenDialog.apply(this, args);
        };

        this._balloon = this.editor.plugins.get(ContextualBalloon);
        this.formView = this._createFormView();

        const view = this.editor.editing.view;
        const viewDocument = view.document;
        view.addObserver(DoubleClickObserver);

        editor.ui.componentFactory.add('wiris', locale => {
            const buttonView = new ButtonView(locale);

            buttonView.set({
                label: 'Wiris Editor',
                tooltip: true,
                withText: false,
                icon: wirisIcon
            });

            buttonView.on('execute', () => {
                this._openDialog(null, buttonView);
            });

            return buttonView;
        });

        this._registerDoubleClickListener(viewDocument);
    }

    _registerDoubleClickListener(viewDocument) {
        this.editor.listenTo(
            viewDocument,
            'dblclick',
            (evt, data) => {
                const domTarget = data.domTarget;
                const parentElement = domTarget.parentElement;
                const sourceAttribute = parentElement.attributes['data-source'];

                const hasWirisClass = Array.from(parentElement.classList).some(className => className.includes('wiris'));
                const isSVGSource = sourceAttribute && b64_to_utf8(sourceAttribute.value).includes('<math');

                if (hasWirisClass || isSVGSource) {
                    if (sourceAttribute) {
                        const decodedSource = b64_to_utf8(sourceAttribute.value);
                        console.log('DB CLICK ==>', decodedSource);
                        this._openDialog(null, decodedSource);
                    }
                }
            },
            { context: 'span' }
        );
    }
    
    _openDialog(buttonView, initialData = null) {
        const editor = this.editor;
        const dialog = this.editor.plugins.get('Dialog');
        const angularInjector = angular.element('*[data-ng-app]').injector();
        const SheetLeaveService = angularInjector.get('SheetLeaveService');
        const editorId = editor.sourceElement.getAttribute('id').replace('_ck5', '');

        this.isDialogVisible = true;
        SheetLeaveService.bindOnLeaveCallBack(
            'wiriseditor_' + editorId,
            () => {
                return this._sheetOnBlurErrorsCallBackFn();
            }
        );

        var random_id = angular.createUUID();
        var wirisEditorInstance = null;
        var isCMS = window.location.href.indexOf("index-cms") > -1 || window.location.href.indexOf("index-pdf") > -1 || window.location.href.indexOf("exam-export-pdf") > -1;

        window.signals.on('iFrameRegisterObject', function (data) {
            if (data.uuid === random_id) {
                wirisEditorInstance = data.object;
                if (initialData) {
                    console.log("Initial data before processing:", initialData);
                    let decodedData;
                    try {
                        if (typeof initialData === 'string') {
                            decodedData = initialData.startsWith('<math') ? initialData : b64_to_utf8(initialData);
                        } else {
                            console.warn("initialData is not a string, using it as-is:", initialData);
                            decodedData = b64_to_utf8(initialData);
                        }
                        console.log("Decoded data:", decodedData);
                    } catch (e) {
                        console.error("Error decoding initialData:", e);
                        decodedData = initialData;
                    }
                    wirisEditorInstance.setMathML(decodedData);
                }
            }
        });

        var widgetData;
        var setInitialData = function (data) {
            if (data.uuid === random_id && widgetData.data.mathml) {
                wirisEditorInstance.setMathML(widgetData.data.mathml);
            }
        };

        function isOnline() {
            if (!isCMS) {
                return false;
            }
            return window.location.protocol == 'https:' || window.location.protocol == 'http:';
        }

        // If the button is turned on, hide the dialog.
        if (buttonView && buttonView.isOn) {
            dialog.hide();
            buttonView.isOn = false;
            return;
        }

        if (buttonView) {
            buttonView.isOn = true;
        }

        const locale = editor.locale;
        const textView = new View(locale);
        window.signals.emit('initFreezeTimeout', 1000);

        textView.setTemplate({
            tag: 'iframe',
            attributes: {
                src: '/iframe-tools/wiris/wiris' + (isOnline() ? 'Online' : 'Offline') + '.html?toolbar=short&language=en&uuid=' + random_id,
                style: {
                    whiteSpace: 'initial',
                    width: '580px',
                    height: '21em'
                },
                tabindex: -1
            },
        });

        dialog.show({
            title: ' ',
            content: textView,
            actionButtons: [
                {
                    label: 'Insert',
                    class: 'ck-button-action',
                    withText: true,
                    onExecute: (widget) => {
                        const mathML = wirisEditorInstance.getMathML();
                        console.log('MathML before encoding:', mathML);
                        const encodedMathML = utf8_to_b64(mathML);
                        console.log('Encoded MathML:', encodedMathML);
                        editor.execute('addWiris', encodedMathML);
                        this.isDialogVisible = false;
                        dialog.hide();
                    }
                },
                {
                    label: 'Cancel',
                    withText: true,
                    onExecute: () => {
                        this.isDialogVisible = false;
                        dialog.hide();
                        window.signals.emit('initFreezeTimeout');
                    }
                }
            ],
            onHide: () => {
                this.isDialogVisible = false;
                if (buttonView) buttonView.isOn = false;
            }
        });

        // Cleanup wenn der Dialog zerstört wird
        return () => {
            if (wirisEditorInstance) {
                wirisEditorInstance = null;
            }
            this.isDialogVisible = false;
            window.signals.emit('initFreezeTimeout');
        };
    }

    _createFormView() {
        const editor = this.editor;
        const formView = new FormView(editor.locale);

        this.listenTo(formView, 'submit', () => {
            const value = {
                data: formView.dataInputView.fieldView.element.value
            };
            editor.execute('addWiris', value);

            this._hideUI();
        });

        this.listenTo(formView, 'cancel', () => {
            this._hideUI();
        });

        clickOutsideHandler({
            emitter: formView,
            activator: () => this._balloon.visibleView === formView,
            contextElements: [this._balloon.view.element],
            callback: () => this._hideUI()
        });

        return formView;
    }

    _showUI() {
        const selection = this.editor.model.document.selection;

        const commandValue = this.editor.commands.get('addWiris').value;

        this._balloon.add({
            view: this.formView,
            position: this._getBalloonPositionData()
        });

        if (commandValue) {
            this.formView.dataInputView.fieldView.value = commandValue.data;
        } else {
            const selectedText = getRangeText(selection.getFirstRange());
            this.formView.dataInputView.fieldView.value = '';
        }

        this.formView.focus();
    }

    _hideUI() {
        this.formView.dataInputView.fieldView.value = '';
        this.formView.element.reset();
        this._balloon.remove(this.formView);
        this.editor.editing.view.focus();
    }

    _getBalloonPositionData() {
        const view = this.editor.editing.view;
        const viewDocument = view.document;
        let target = null;

        target = () => view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange());

        return {
            target
        };
    }

    destroy() {
        this.isDialogVisible = false;
        const angularInjector = angular.element('*[data-ng-app]').injector();
        const SheetLeaveService = angularInjector.get('SheetLeaveService');
        
        if (this.editor && this.editor.sourceElement) {
            SheetLeaveService.unbindOnLeaveCallBack('wiriseditor_' + this.editor.sourceElement.getAttribute('id').replace('_ck5', ''));
        }
        
        super.destroy();
    }
}